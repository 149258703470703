<template>
  <div>
    <div class="row my-3">
      <h1 class="col-sm-12 text-right">Viaticos</h1>
    </div>

    <DataGrid :config="dataGrid_config" :data="viaticos" :select="seleccionar" @actions="dataGridActions" @ready="buscar_botones">
      <div class="row">
        <div class="col-sm-12"><button class="btn btn-warning" @click="obtener_viaticos">Recargar</button></div>
      </div>
    </DataGrid>

    <Info v-if="modal_info" origen="autorizacion" :catalogo="catalogo" :viatico_id="viatico.id" @close="modal_info=false" @update="obtener_viaticos" />
  </div>
</template>

<script>
import DataGrid from '@/components/DataGridV2';

import Info from './Viatico/Info';
import config from '@/apps/tesoreria/configuracion/viaticos'

import apiCatalogo from '@/apps/tesoreria/api/catalogos'
import api from '@/apps/tesoreria/api/viaticos';

export default {
  components: {
    DataGrid, Info
  }
  ,data() {
    return {
      dataGrid_config: {
        name: 'viaticos',
        select_items: false,
        cols: {
          nombre: 'Nombre',
          identificador: 'Identificador',
          monto: 'Monto',
          estatus_nombre: 'Estatus',
          created_at: 'Creación',
          opciones: 'Opciones'
        },
        paginator: {
          pagina_actual: 1
					,total_registros: 1
					,registros_por_pagina: 20
        },
        mutators: {
          monto(val, row, vue) {
            return '$'+vue.$helper.moneyFormat(val);
          },
          created_at(val, row, vue) {
            return vue.$moment(val).format('ddd DD MMM YYYY HH:mm')
          },
          opciones(val, row, vue) {
            return '<button class="btn btn-primary btn-viaticos" id="viatico_'+row.id+'" data-viatico="'+vue.$helper.toJson(row)+'">Revisar</button>';
          }
        }
      },
      options: {
        page: 1
				,order_col: 'id'
				,order_dir: 'desc'
				,limit: 20
				,filters: []
      }
      ,viaticos: []
      ,selecionadas: []
      ,seleccionar: false
      ,viatico: {
        id: null,
        autorizador_id: null,
        account_id: null,
        nombre: null,
        identificador: null,
        descripcion: null,
        monto: null,
        estatus: null
      }
      ,catalogo: []
      ,modal_info: false
    }
  }
  ,mounted() {
    
  }
  ,methods: {
    dataGridActions: function(tipo, data) {
      if (tipo == 'options') {
        this.options = data;
        this.obtener_catalogo();
      }else {
        this.seleccionadas = data;
        this.seleccionar = true;
      }
    }
    ,async obtener_catalogo() {
      try {
        if (this.catalogo.length > 0)
          return this.obtener_viaticos();

        let res = (await apiCatalogo.buscar_catalogo('estatus_viaticos')).data;
        this.$log.info('catalogo', res);
        this.catalogo = res.valores;

        this.obtener_viaticos();
      }catch(e) {
        this.$log.info('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    }
    ,async obtener_viaticos() {
      try {
        let res = (await api.autorizacion_obtener_viaticos(this.options)).data;
        this.viaticos = res.data;

        for(let i=0; i<this.viaticos.length; i++) {
          for(let a=0; a<this.catalogo.length; a++) {
            if (this.viaticos[i].estatus == this.catalogo[a].valor) {
              this.viaticos[i].estatus_nombre =this.catalogo[a].nombre;
              a = this.catalogo.length;
            }
          }
        }

        this.dataGrid_config.paginator.pagina_actual = res.current_page;
        this.dataGrid_config.paginator.total_registros = res.total;
        this.dataGrid_config.paginator.registros_por_pagina = parseInt(res.per_page);
        this.$log.info('res', res)
      }catch(e) {
        this.$log.info('errpr',e);
        this.$helper.showAxiosError(e,'Error');
      }
    }
    ,buscar_botones() {
      let btns = document.querySelectorAll('.btn-viaticos');

      btns.forEach(btn => {
        btn.removeEventListener('click', this.revisar_viatico);
        btn.addEventListener('click', this.revisar_viatico);
      })
    }
    ,revisar_viatico(e) {
      this.viatico = this.$helper.fromJson(e.target.dataset.viatico);
      this.modal_info = true;
    }
  }
  ,computed: {
    catalogo_estatus() {
      return config.viaticos.constantes;
    }
  }
}
</script>